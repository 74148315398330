import React, { useState, useEffect } from 'react';
import './styles/App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { callMsGraph } from './graph';
import { useMsalAuthentication, useMsal, useAccount } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";
import { OwnerTable } from "./components/tables/Pages/OwnerTable";
import { CollabTable } from "./components/tables/Pages/CollabTable";
import { CSVLink } from "react-csv";
import {PopUp} from "./components/PopUp/Modal"
import logo from "./components/assets/logo.png";
import { UserAgentApplication } from 'msal';
import {callAPI} from "./api"
import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
    Box,
    Heading,
    Link,
    VStack,
    Flex,
    Spacer,
    Image,
    Button,
    Text,
    Spinner
  } from "@chakra-ui/react";
/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
// Dummy Commit
// Dummy Commit Test
const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    //const [AccessToken, setAccessToken] = useState(null);

    // Silently acquires an access token which is then attached to a request for MS Graph data
    // const fetchCreds = async () => {
    // const response = await instance, dummy commit
    // .acquireTokenSilent({
    //     ...loginRequest,
    //     account: accounts[0],
    // })
    // setAccessToken(response.accessToken)
    //   return response
    
    // }
    

    // useEffect(() => {
    //   fetchCreds()
    //   .then((response) => {
    //     callMsGraph(response.accessToken)
    //     .then((response) => setGraphData(response));
    // });
    // }, [])

    const fetchCreds = async () => 
    { 
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
    }).then((response) => {
      callMsGraph(response.accessToken).then(response => setGraphData(response));
  });
    
    }
    useEffect(() => {
      fetchCreds()
    }, [])


    
    if (!graphData) {
      console.log("Loading SSO...")
      return null

    }

    let props = {
      //token: AccessToken,
      email: graphData.mail
    }
    console.log(graphData.mail)

    return (
      <UserData {...props}/>
    );
    
};

const UserData = (props) => {
  //] = useState(null);
  const { accounts } = useMsal();
  const [CollaboratorApiData, CollaboratorSetApiData] = useState(null);
  const [OwnerApiData, OwnerSetApiData] = useState(null);
  const account = useAccount(accounts[0] || {});

  const msalConfig = {
    auth: {
        clientId: `${process.env.REACT_APP_nodeAPIclientID}/Files.Read`,
        authority: "https://login.microsoftonline.com/15ccb6d1-d335-4996-b6f9-7b6925f08121",
        redirectUri: process.env.REACT_APP_RedirectURL,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

const msalInstance = new UserAgentApplication(msalConfig);
// console.log(account)


    const GetApiToken = async () => {
   const response = await msalInstance.acquireTokenSilent({
      scopes: [`api://${process.env.REACT_APP_nodeAPIclientID}/Files.Read`],
      account: account})
 return response
    };

    useEffect(()=> {
      if (accounts.length > 0) {
        GetApiToken()
      .then((response) => {
        if(response) { // props.email.toLowerCase()
          callAPI(response.accessToken,'Collaborator', props.email.toLowerCase())
        .then((result) => CollaboratorSetApiData(result))
      }
      })
      }
    }, [])

    useEffect(()=> {
      if (accounts.length > 0) {
        GetApiToken()
      .then((response) => {
        if(response) {
          callAPI(response.accessToken,'Owner', props.email.toLowerCase())
        .then((result) => OwnerSetApiData(result))
      }
      })
      }
    }, [])


    // if (!OwnerApiData) {
    //   return 
    //   <VStack alignItems={'center'}><Heading size={'lg'} color={'white'}>Loading...</Heading><Spinner
    //   thickness='4px'
    //   speed='0.8s'
    //   emptyColor="#0C2340"
    //   color='white'
    //   size='xl'
    // /></VStack>;
    // }
  
    // if (!CollaboratorApiData) {
    //   return <VStack alignItems={'center'}><Heading size={'lg'} color={'white'}>Loading...</Heading><Spinner
    //   thickness='4px'
    //   speed='0.8s'
    //   emptyColor= "#0C2340"
    //   color='white'
    //   size='xl'
    // /></VStack>
    // }

  return (
<>
    {(CollaboratorApiData && OwnerApiData) ? 
    <VStack pt={4}  alignItems={'center'}>
    <Heading textColor={'white'} fontFamily="Arial" size="md">
    Box Files/Folders I Own Containing Collaborators Outside My Business (you can reinvite the collaborators if needed)
          </Heading>
          <OwnerTable data = {OwnerApiData} />
          {OwnerApiData.length? 
          <Button textColor={'white'} bgColor={"brand.Blue"} alignSelf={"end"}>
            <CSVLink filename='Box_Owner_Report' data={OwnerApiData}>Export CSV</CSVLink>
          </Button> : <></>
}
          <Heading textColor={'white'} fontFamily="Arial" size="md">
          Box Files/Folders Owned By a User Outside of My Business That I Lost Access To (owner can reinvite if needed)
          </Heading>
          <CollabTable data = {CollaboratorApiData} />
          {CollaboratorApiData.length? 
          <Button textColor={'white'}  bgColor={"brand.Blue"} alignSelf={"end"}>
            <CSVLink CSVlink filename='Box_Collab_Report' data={CollaboratorApiData}>Export CSV</CSVLink>
          </Button> : <></>
}
          </VStack> 
          
          : 
          
          <VStack alignItems={'center'}>
          <Heading size={'lg'} color={'white'}>Loading...</Heading><Spinner
          thickness='4px'
          speed='0.8s'
          emptyColor= "#0C2340"
          color='white'
          size='xl'
        />
        </VStack>
}
        </>
  );

}
/**
 * If a user is authenticated the ProfileContent {Dummy Commit} component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */

export  default function App() {
    useMsalAuthentication(InteractionType.Redirect);
    return (
      <>
        <AuthenticatedTemplate>
            <Box>
      <Flex align={"center"}>
        <Spacer />
        <Image w="100px" src={logo} />
      </Flex>
      <Flex justify={"center"} minH="vh100">
        <VStack>
          <Heading
            pt={"2"}
            pos="absolute"
            top="10"
            alignSelf={"center"}
            pb={"1"}
            fontFamily="Arial"
            size="lg"
            color={'white'}
          >
          GE to GE Vernova Box Collaboration Review Portal
          </Heading>
          <VStack alignItems={'start'} bgColor={'white'} rounded={10} p={3}>
            <Box maxW='65rem'>
          <Heading size={'md'} fontFamily={'arial'} color={"colors.DarkIndigo"} mb={1}>What has happened?</Heading>

          <Text fontSize='sm' fontFamily={'arial'} color={"colors.DarkIndigo"}>As part of the <Text as={'b'}   >GE Vernova separation, GE Vernova Box data has been migrated to a dedicated Box environment.</Text>
            {" "}To maintain compliance with separation requirements and our data protection policies, many cross-business collaborations between GE Vernova and GE Box users have been severed. This may result in some disruption to the access users have to data across businesses.</Text>
          <br /><Heading size={'md'} fontFamily={'arial'} color={"colors.DarkIndigo"} mb={1}>What Do These Tables Contain?</Heading>
          <Text color={"colors.DarkIndigo"} fontSize='sm'>The first table displays files/folders you own that have cross-business collaborations. As of March 22nd, these users no longer have access to the items outlined in this table.
            <Text  as={'b'}    > If the user requires continued access, you as the owner will need to reinstate their access within Box. </Text><Text as={'u'}>Please refer to the instructions included<Link a='b' color={'blue'} href="https://geit.service-now.com/kb?id=kb_article_view&sysparm_article=GEKB2003326" isExternal> here<ExternalLinkIcon mx='1px' color={'colors.DarkIndigo'} /></Link></Text></Text>


            <Text fontSize='sm' color={"colors.DarkIndigo"}><br/>The second table displays files/folders owned by another business user that you have lost access to.
            <Text  as={'b'}  > If you require continued access to these files/folders you will need to request this access be reinstated by the named item owner.</Text></Text>

            </Box>
          </VStack>
          <Box>
          <ProfileContent />
          </Box>
          </VStack>
          
      </Flex>
      <PopUp/>
    </Box>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
            <h5 className="card-title">Please sign-in to see your profile information.</h5>
        </UnauthenticatedTemplate>
    </>
  );
}
